@font-face {
  font-family: 'Upheaval TT';
  src: url('./fonts/upheavtt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  text-align: center;
  font-size: calc(60px + 2vmin);
  color: white;
  font-family: 'Upheaval TT', sans-serif;
}

p {
  text-align: center;
  font-size: calc(20px + 2vmin);
  color: white;
  font-family: 'Upheaval TT', sans-serif;
}

@media (max-width: 768px) { /* Adjust width as needed for mobile */
  .App, .App-header {
    align-items: center;
  }

  .App-header h1, .App-header p {
    text-align: center;
    padding: 0 20px; /* Add some padding on smaller screens */
  }
  
  /* Adjust sizes for smaller screens */
  .App {
    font-size: calc(8px + 2vmin);
  }
}


